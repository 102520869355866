export default {
  user: (state) => state.user,
  guardian: (state) => state.user,
  guardianName: (state) => state.guardianName,
  guardianFullName: (state) => state.guardianFullName,
  guardianAvatar: (state) => state.user.avatarLink,
  token: (state) => state.token,
  startPageGetter: (state) => state.startPage,
  missionsCategories: (state) => state.missionsCategories,
  miniMissions: (state) => state.miniMissions,
  miniMissionsNewGetter: (state) => state.miniMissionsNew,
  miniMissionsNewArrayGetter: (state) => state.miniMissionsNewArray,
  miniMissionsNewHistoryArrayGetter: (state) => state.miniMissionsNewHistoryArray,
  miniMissionsNewPendingRewardArrayGetter: (state) => state.miniMissionsNewPendingRewardArray,
  megaMissions: (state) => state.megaMissions,
  skillsMissions: (state) => state.skillsMissions,
  sidebarCurrentMission: (state) => state.sidebarCurrentMission,
  dashboardActivities: (state) => state.dashboardActivities,
  childrensActivity: (state) => state.childrensActivity,
  dashboardChildrens: (state) => state.dashboardChildrens,
  familyAndFriends: (state) => state.familyAndFriends,
  childrensList: (state) => state.childrensList,
  paymentSuccess: (state) => state.paymentSuccess,
  pastMissions: (state) => state.pastMissions,
  guardianTransactions: (state) => state.guardianTransactions,
  guardianTransactionsGetter: (state) => state.guardian_transactions,
  childrensFunds: (state) => state.childrensFunds,
  childrensTransactions: (state) => state.childrensTransactions,
  childrensMain: (state) => state.childrensMain,
  childrensClosed: (state) => state.childrensClosed,
  spendingWallet: (state) => state.spendingWallet,
  savingsWallet: (state) => state.savingsWallet,
  ratingStars: (state) => state.ratingStars,
  addresses: (state) => state.guardianAddresses,
  guardianFamilyName: (state) => state.familyName,
  loginRequests: (state) => state.loginRequests,
  guardianBalance: (state) => state.balance,
  childSuspendedGetter: (state) => state.childSuspended,
  missionWishlistGetter: (state) => state.missionWishlist,
  youtubeVideosGetter: (state) => state.youtubeVideos,
  wizardStepGetter: (state) => state.wizardStep,
  transactionsGetter: (state) => state.transactions,
  modulrStatusGetter: (state) => state.modulrStatus,
  accountSetupStatusGetter: (state) => state.accountSetupStatus,
  getSetupChildrens: (state) => state.setup.children,
  childrens: (state) => state.childrens,
  getSetupMissions: (state) => state.setup.missions,
  getSetupChildrenMissions: (state) => state.setup.missions,
  pendingRewardMissionsGetter: (state) => state.pendingRewardMissions,
  dashboardMissionsInprogressGetter: (state) => state.dashboardMissions.in_progress,
  dashboardMissionsCompletedGetter: (state) => state.dashboardMissions.completed,
  dashboardMissionsFailedGetter: (state) => state.dashboardMissions.failed,
  dashboardMissionsExpiredGetter: (state) => state.dashboardMissions.expired,
  setupChildrenGetter: (state) => state.setupChildren,
  miniMissionsLengthGetter: (state) => state.miniMissionsLength,
  lastAddedChildGetter: (state) => state.lastAddedChild,
};
