import Vue from "vue";
import Vuex from "vuex";
//import createPersistedState from "vuex-persistedstate";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    userId: "",
    token: "",
    user: [],
    setup: {
      children: [],
      missions: [],
      money: null,
      address: null,
      activeChildId: null
    },
    lastAddedChild: null,
    setupChildren: [],
    setupMissions: [],
    hasNotifications: false,
    isProfilePage: false,
    modulrStatus: 0,
    accountSetupStatus: 0,
    componentKey: 0,
    missionSelectedMoney: false,
    wizardStep: 0,
    pendingRewardActive: false,
    pendingAddressModal: false,
    pendingMissionId: null,
    wizardSteps: null,
    readyToPay: false,
    allMissions: [],
    loginRequests: [],
    startPage: null,
    pastMissionsModal: false,
    wizardLoading: false,
    childAddModal: false,
    balanceModal: false,
    notification: false,
    paymentSubmit: false,
    childSuspended: 0,
    rewardType: true,
    wizardCompleted: 0,
    guardianMobile: false,
    guardianEmail: false,
    guardiaPhoneNumber: "",
    childAvatar: "",
    familyName: "",
    ratingStars: 0,
    isLoading: false,
    guardianName: "",
    guardianFullName: "",
    wizard_canceled: false,
    miniMissions: [],
    miniMissionsNew: [],
    miniMissionsNewArray: [],
    miniMissionsNewHistoryArray: [],
    miniMissionsNewPendingRewardArray: [],
    megaMissions: [],
    skillsMissions: [],
    missionWishlist: [],
    miniMissionsLength: 0,
    firstChild: "",
    sidebarCurrentMission: [],
    dashboardActivities: [],
    childrens: [],
    childrensActivity: [],
    dashboardChildrens: [],
    missionsCategories: [],
    familyAndFriends: [],
    childrensList: [],
    pastMissions: [],
    guardianTransactions: [],
    childrensTransactions: [],
    childrensMain: [],
    childrensClosed: [],
    childrensGoals: [],
    childrensFunds: [],
    transactions: [],
    guardian_transactions: [],
    globaLoading: false,
    spendingWallet: 0,
    savingsWallet: 0,
    missions: [],
    balance: 0,
    spendReward: 0,
    paymentSuccess: false,
    address: "",
    welcome: true,
    testing: [],
    missionSide: [],
    misiuniCopil: [],
    childrenINFO: [],
    guardian: {
      childrens: "",
      members: ""
    },
    guardianAddresses: [],
    youtubeVideos: [],
    deliveryAddresses: [],
    pendingRewardMissions: [],
    dashboardMissions: {
      in_progress: [],
      completed: [],
      failed: [],
      expired: [],
    },
  },
  actions,
  getters,
  mutations
  //plugins: [createPersistedState()]
});
