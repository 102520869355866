import path from "path";

var dev = false;

var API_HOST,
  HOST,
  HOMEPAGE,
  BASE_DIR,
  LOCAL_HOST,
  GUARDIAN_HOST,
  CHILD_HOST,
  LOCAL_HOMEPAGE,
  LOCAL_HOMEPAGE_MOBILE,
  HOMEPAGE_MOBILE;

if (dev) {
  API_HOST = "https://api1.dev.sonikpocket.com/api";
  HOST = "https://api1.dev.sonikpocket.com/";
  HOMEPAGE = "https://dev.sonikpocket.com/";
  HOMEPAGE_MOBILE = "https://dev.sonikpocket.com/m/guardian/login";
  BASE_DIR = path.join(__dirname, "../");
  LOCAL_HOST = "http://192.168.100.29:8080";
  // LOCAL_HOST = "http://localhost:8080";
  LOCAL_HOMEPAGE_MOBILE = "http://localhost:5000/m/guardian/login";
  // LOCAL_HOMEPAGE_MOBILE = "http://192.168.100.14:5000/m/guardian/login";
  // LOCAL_HOMEPAGE = "http://localhost:5000";
  LOCAL_HOMEPAGE = "http://192.168.100.29:5000";
  GUARDIAN_HOST = "https://guardian.dev.sonikpocket.com";
  CHILD_HOST = "https://children.dev.sonikpocket.com";
} else {
  API_HOST = "https://api1.sonikpocket.com/api";
  HOST = "https://api1.sonikpocket.com/";
  HOMEPAGE = "https://sonikpocket.com/";
  HOMEPAGE_MOBILE = "https://sonikpocket.com/m/guardian/login";
  BASE_DIR = path.join(__dirname, "../");
  LOCAL_HOMEPAGE_MOBILE = "http://192.168.100.14:5000/m/guardian/login";
  // LOCAL_HOST = "http://localhost:8080";
  LOCAL_HOST = "http://192.168.100.29:8080";
  LOCAL_HOMEPAGE = "http://192.168.100.14:5000";
  GUARDIAN_HOST = "https://guardian.sonikpocket.com";
  CHILD_HOST = "https://children.sonikpocket.com";
}

export default {
  API_HOST,
  HOST,
  HOMEPAGE,
  BASE_DIR,
  LOCAL_HOST,
  LOCAL_HOMEPAGE,
  GUARDIAN_HOST,
  CHILD_HOST,
  HOMEPAGE_MOBILE,
  LOCAL_HOMEPAGE_MOBILE,
};

//Dev
// export default {
// 	API_HOST: 'https://api1.dev.sonikpocket.com/api/',
// 	HOST: 'https://api1.dev.sonikpocket.com/',
// 	HOMEPAGE: 'https://dev.sonikpocket.com/',
// 	BASE_DIR: path.join(__dirname,'../'),
// 	TEST_HOST: 'http://localhost:8080',
// 	GUARDIAN_HOST: 'https://guardian.dev.sonikpocket.com',
// 	CHILD_HOST:'https://children.dev.sonikpocket.com',
// };

// //Productie
// export default {
//     API_HOST: 'https://api1.sonikpocket.com/api/',
//     HOST: 'https://api1.sonikpocket.com/',
//     HOMEPAGE: 'https://sonikpocket.com/',
//     BASE_DIR: path.join(__dirname,'../'),
//     // GUARDIAN_HOST: 'http://localhost:8080',
//     GUARDIAN_HOST: 'https://guardian.sonikpocket.com',
//     CHILD_HOST:'https://children.sonikpocket.com',
// };
