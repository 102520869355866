import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "../pages/guardian/store/store";

Vue.use(VueRouter);

// function requireAuth(to, from, next) {
//   console.log(!store.state.user)
//   if (!store.state.user || !store.state.token) {
//     next({
//       path: '/login'
//     })
//   } else {
//     next()
//   }
// }

const routes = [
  {
    path: "",
    component: () =>
      import(/* webpackChunkName: "login-layout" */ "../layouts/Login.vue"),
    // beforeEnter: requireAuth,
    children: [
      {
        path: "/",
        name: "main-index",
        component: () =>
          import(
            /* webpackChunkName: "main-index" */ "../pages/login/views/Home.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
        // redirect: to => {
        //   return { path: '/guardian', query: { } }
        // },
      },
      {
        path: "/child-login",
        name: "child-login",
        component: () =>
          import(
            /* webpackChunkName: "child-login" */ "../pages/login/views/ChildLogin.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
      {
        path: "/child-login-pincode",
        name: "child-login-pincode",
        component: () =>
          import(
            /* webpackChunkName: "child-login-pincode" */ "../pages/login/views/ChildLoginPincode.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
      {
        path: "/parent-login",
        name: "parent-login",
        component: () =>
          import(
            /* webpackChunkName: "parent-login" */ "../pages/login/views/ParentLogin.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
      {
        path: "/register",
        name: "register",
        component: () =>
          import(
            /* webpackChunkName: "register" */ "../pages/login/views/Register.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
      {
        path: "/recover",
        name: "recover",
        component: () =>
          import(
            /* webpackChunkName: "recover" */ "../pages/login/views/Recover.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
      {
        path: "/recover-guardian-password",
        name: "recover-guardian-password",
        component: () =>
          import(
            /* webpackChunkName: "recover-guardian-password" */ "../pages/login/views/RecoverGuardianPassword.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
      {
        path: "/help",
        name: "help",
        component: () =>
          import(
            /* webpackChunkName: "help" */ "../pages/login/views/Help.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ "../pages/login/views/ResetPassword.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
      {
        path: "/terms-and-conditions",
        name: "terms-and-conditions",
        component: () =>
          import(
            /* webpackChunkName: "terms-and-conditions" */ "../pages/login/views/T&C.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () =>
          import(
            /* webpackChunkName: "privacy-policy" */ "../pages/login/views/PrivacyPolicy.vue"
          ),
        meta: { guest: true },
        // beforeEnter: requireAuth,
      },
    ],
  },
  {
    path: "",
    component: () =>
      import(
        /* webpackChunkName: "guardian-layout" */ "../layouts/Guardian.vue"
      ),
    children: [
      {
        path: "/guardian",
        name: "guardian",
        component: () =>
          import(
            /* webpackChunkName: "guardian-home" */ "../pages/guardian/pages/Dashboard/Home.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/missions",
        name: "guardian-missions",
        component: () =>
          import(
            /* webpackChunkName: "guardian-missions" */ "../pages/guardian/pages/Dashboard/Missions.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/profile",
        name: "guardian-profile",
        component: () =>
          import(
            /* webpackChunkName: "guardian-profile" */ "../pages/guardian/pages/Dashboard/Profile.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/children",
        name: "guardian-children",
        component: () =>
          import(
            /* webpackChunkName: "guardian-children" */ "../pages/guardian/pages/Dashboard/Children.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/financial",
        name: "guardian-financial",
        component: () =>
          import(
            /* webpackChunkName: "guardian-financial" */ "../pages/guardian/pages/Dashboard/Financial.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/modulr-verification",
        name: "modulr-verification",
        component: () =>
          import(
            /* webpackChunkName: "modulr-verification" */ "../pages/guardian/pages/Dashboard/Modulr.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/add-funds",
        name: "add-funds",
        component: () =>
          import(
            /* webpackChunkName: "add-funds" */ "../pages/guardian/pages/Dashboard/AddFunds.vue"
          ),
        meta: { requiresAuth: true },
      },

      {
        path: "/guardian/setup",
        name: "guardian-setup",
        component: () =>
          import(
            /* webpackChunkName: "setup-index" */ "../pages/guardian/pages/Setup/Index.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/setup/family",
        name: "setup-family",
        component: () =>
          import(
            /* webpackChunkName: "setup-family" */ "../pages/guardian/pages/Setup/Family.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/setup/missions",
        name: "setup-missions",
        component: () =>
          import(
            /* webpackChunkName: "setup-missions" */ "../pages/guardian/pages/Setup/Missions.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/setup/money",
        name: "setup-money",
        component: () =>
          import(
            /* webpackChunkName: "setup-money" */ "../pages/guardian/pages/Setup/Money.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/guardian/setup/children",
        name: "setup-children",
        component: () =>
          import(
            /* webpackChunkName: "setup-children" */ "../pages/guardian/pages/Setup/Children.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
  // {
  //   path: "",
  //   component: () =>
  //     import(/* webpackChunkName: "setup-layout" */ "../layouts/Setup.vue"),
  //   children: [
  //     {
  //       path: "/guardian/setup",
  //       name: "guardian-setup",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "setup-index" */ "../pages/guardian/pages/Setup/Index.vue"
  //         ),
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/guardian/setup/family",
  //       name: "setup-family",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "setup-family" */ "../pages/guardian/pages/Setup/Family.vue"
  //         ),
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/guardian/setup/missions",
  //       name: "setup-missions",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "setup-missions" */ "../pages/guardian/pages/Setup/Missions.vue"
  //         ),
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/guardian/setup/money",
  //       name: "setup-money",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "setup-money" */ "../pages/guardian/pages/Setup/Money.vue"
  //         ),
  //       meta: { requiresAuth: true },
  //     },
  //     {
  //       path: "/guardian/setup/children",
  //       name: "setup-children",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "setup-children" */ "../pages/guardian/pages/Setup/Children.vue"
  //         ),
  //       meta: { requiresAuth: true },
  //     },
  //   ],
  // },
  {
    path: "",
    component: () =>
      import(
        /* webpackChunkName: "children-layout" */ "../layouts/Children.vue"
      ),
    children: [
      {
        path: "/children",
        name: "children",
        component: () =>
          import(
            /* webpackChunkName: "children-home" */ "../pages/children/pages/Dashboard/Home.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/missions",
        name: "children-missions",
        component: () =>
          import(
            /* webpackChunkName: "children-missions" */ "../pages/children/pages/Dashboard/Missions.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/profile",
        name: "children-profile",
        component: () =>
          import(
            /* webpackChunkName: "guardian-profile" */ "../pages/children/pages/Dashboard/Profile.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/wishlist",
        name: "children-wishlist",
        component: () =>
          import(
            /* webpackChunkName: "children-wishlist" */ "../pages/children/pages/Dashboard/Wishlist.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/goals",
        name: "children-goals",
        component: () =>
          import(
            /* webpackChunkName: "children-goals" */ "../pages/children/pages/Dashboard/Goals.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/playground",
        name: "children-playground",
        component: () =>
          import(
            /* webpackChunkName: "children-playground" */ "../pages/children/pages/Dashboard/Playground.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/shop",
        name: "children-shop",
        component: () =>
          import(
            /* webpackChunkName: "children-shop" */ "../pages/children/pages/Dashboard/Shop.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/gifts",
        name: "children-gifts",
        component: () =>
          import(
            /* webpackChunkName: "children-gifts" */ "../pages/children/pages/Dashboard/Gifts.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/journey",
        name: "children-journey",
        component: () =>
          import(
            /* webpackChunkName: "children-journey" */ "../pages/children/pages/Dashboard/Journey.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/product_details_wishlist",
        name: "children-product_details_wishlist",
        component: () =>
          import(
            /* webpackChunkName: "children-product_details_wishlist" */ "../pages/children/pages/Dashboard/ProductDetails.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/checkout_game_shop",
        name: "children-checkout_game_shop",
        component: () =>
          import(
            /* webpackChunkName: "children-checkout_game_shop" */ "../pages/children/pages/Dashboard/CheckoutGame.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/checkout_game_goal",
        name: "children-checkout_game_goal",
        component: () =>
          import(
            /* webpackChunkName: "children-checkout_game_goal" */ "../pages/children/pages/Dashboard/CheckoutGame.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/checkout_game_wishlist",
        name: "children-checkout_game_wishlist",
        component: () =>
          import(
            /* webpackChunkName: "children-checkout_game_wishlist" */ "../pages/children/pages/Dashboard/CheckoutGame.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/checkout_shop",
        name: "children-checkout_shop",
        component: () =>
          import(
            /* webpackChunkName: "children-checkout_shop" */ "../pages/children/pages/Dashboard/Checkout.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/checkout_goal",
        name: "children-checkout_goal",
        component: () =>
          import(
            /* webpackChunkName: "children-checkout_goal" */ "../pages/children/pages/Dashboard/Checkout.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/checkout_wishlist",
        name: "children-checkout_wishlist",
        component: () =>
          import(
            /* webpackChunkName: "children-checkout_wishlist" */ "../pages/children/pages/Dashboard/Checkout.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/product_details_shop",
        name: "children-product_details_shop",
        component: () =>
          import(
            /* webpackChunkName: "children-product_details_shop" */ "../pages/children/pages/Dashboard/ProductDetails.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/product_details_goal",
        name: "children-product_details_goal",
        component: () =>
          import(
            /* webpackChunkName: "children-product_details_goal" */ "../pages/children/pages/Dashboard/ProductDetails.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/coins_game_wishlist",
        name: "children-coins_game_wishlist",
        component: () =>
          import(
            /* webpackChunkName: "children-coins_game_wishlist" */ "../pages/children/pages/Dashboard/CoinsGame.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/coins_game_shop",
        name: "children-coins_game_shop",
        component: () =>
          import(
            /* webpackChunkName: "children-coins_game_shop" */ "../pages/children/pages/Dashboard/CoinsGame.vue"
          ),
        meta: { requiresAuth: true },
      },
      {
        path: "/children/coins_game_goals",
        name: "children-coins_game_goals",
        component: () =>
          import(
            /* webpackChunkName: "children-coins_game_shop" */ "../pages/children/pages/Dashboard/CoinsGame.vue"
          ),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/404",
    components: {
      default: () =>
        import(
          /* webpackChunkName: "main-index" */ "../pages/login/views/404.vue"
        ),
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "guardian") {
    console.log("guardian");
  }

  if (to.name === "children") {
    console.log("children");
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      localStorage.getItem("user_type") === "guardian" &&
      localStorage.getItem("guardian_token") &&
      localStorage.getItem("guardian_user_id") &&
      localStorage.getItem("guardian_family_id")
    ) {
      next();
      return;
    }

    if (
      localStorage.getItem("user_type") === "child" &&
      localStorage.getItem("children_token") &&
      localStorage.getItem("children_user_id") &&
      localStorage.getItem("children_family_id")
    ) {
      next();
      return;
    }

    // next("/");
  } else {
    next();
  }
});

export default router;
