export default {
	success(statusCode) {
		return statusCode >= 200 && statusCode < 300;
	},
	invalid(statusCode) {
		return statusCode === 422;
	},
	invalidToken(statusCode) {
		return statusCode === 401;
	},
	error(statusCode) {
		return statusCode == 400 || statusCode == 401 || statusCode == 403 || statusCode == 404 || statusCode == 406 || statusCode == 415 || statusCode == 500;
	},
};
