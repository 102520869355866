// import config from "@/config";

export default {
  TOAST: () => {},
  SETUP_USER: (state, data) => {
    state.userId = data.user.userId;
    state.token = data.user.token;
    // state.guardianName = data.account.username;
    state.guardianName = data.account.first_name;
    state.user = data.account;
    state.startPage = data.account.start_page;

    //console.log(state.user);

    // console.log(localStorage.getItem('userId'));
    // localStorage.setItem('token', data.user.token);
    // console.log(data);
  },
  GET_MISSIONS_CATEGORIES: (state, data) => {
    // console.log(data);
    data.forEach(function (categories) {
      state.missionsCategories.push(categories);
    });

    //console.log(state.missionsCategories);
  },
  FETCH_USER: (state, data) => {
    state.guardianName = data.account.first_name;
    state.deliveryAddresses = data.account.delivery_addresses;
    state.startPage = data.account.start_page;
    state.user = data.account;
    state.wizardSteps = data.account.wizard_step_remaining;
    state.modulrStatus = data.account.validated_modulr;
    state.accountSetupStatus = data.account.account_setup_status;
    // console.log(state.wizardSteps)
    // console.log(data.account.account_setup_status);
    // console.log("USER DATA");

    data.account.accounts.forEach(function (account) {
      if (account.name == "main") {
        state.spendWallet = account;
      } else if (account.name == "close") {
        state.saveWallet = account;
      }
    });
  },
  FETCH_CHILDRENS: (state, data) => {
    //state.childrens = data.account.username;

    state.childrens = [];
    // console.log("object");
    // console.log(data.data[0]);

    if (data.data.length > 0) {
      localStorage.setItem("activeChild", data.data[0].users_id);
      localStorage.setItem(
        "activeChildBalance",
        data.data[0].user.CurrentBalance
      );
      localStorage.setItem("activeChildActivity", data.data[0].users_id);
      data.data.forEach(function (child) {
        state.childrens.push(child);
        //state.miniMissions.push(child.missions);
        //console.log(child.user);
      });
    }

    // console.log(state.firstChild);

    // console.log("-----------state------------");
    // console.log(state.childrens);
    // console.log("-----------state------------"
  },
  FETCH_MINI_MISSIONS: (state, payload) => {
    //console.log("%c This is the state! ", "background: lightblue; color: #222");
    //console.log(state);
    //state.allMissions = [];
    payload.data.forEach((e) => {
      //console.log(e.missions);
      if (e.users_id == payload.user) {
        e.missions.forEach((k) => {
          if (k.name == "mini") {
            if (k.missions !== null && k.missions !== "") {
              k.missions.forEach(function (mission) {
                state.miniMissions.unshift(mission);
              });
            }
          }
        });
      }
    });

    //console.log(state.allMissions);
    //console.log(state.miniMissions);
  },
  FETCH_MINI_MISSIONS_NEW: (state, payload) => {
    //console.log("%c This is the state! ", "background: lightblue; color: #222");
    // console.log(payload);
    //state.allMissions = [];
    payload.data.forEach((e) => {
      // console.log(e);
      if (e.users_id == payload.user) {
        // console.log(e);
        e.category.forEach((k) => {
          // console.log(k);
          if (k.name == "mini") {
            // console.log("ok");
            if (k.subcategory !== null && k.subcategory !== "") {
              // console.log(k.subcategory.missions);
              state.miniMissionsNewArray = k.subcategory.missions;
              state.miniMissionsNewHistoryArray = k.subcategory.history;
              state.miniMissionsNewPendingRewardArray =
                k.subcategory.pending_reward;
              state.miniMissionsNew = k.subcategory;
            }
          }
        });
      }
    });

    //console.log(state.allMissions);
  },
  FETCH_MINI_MISSIONS_LENGTH: (state, payload) => {
    state.miniMissionsLength = payload[0]?.category?.find((e) => e.name == "mini").subcategory?.missions?.length;
    // console.log(payload[0].category.find((e) => e.name == "mini").subcategory.missions.length);
  },
  FETCH_MEGA_MISSIONS: (state, payload) => {
    //state.allMissions = [];
    payload.data.forEach((e) => {
      //console.log(e.missions);
      if (e.users_id == payload.user) {
        e.missions.forEach((k) => {
          if (k.name == "mega") {
            //console.log(k.missions);
            if (k.missions !== null && k.missions !== "") {
              k.missions.forEach(function (mission) {
                state.megaMissions.unshift(mission);
              });
            }
          }
        });
      }
    });
    //console.log(state.megaMissions);
  },
  FETCH_SKILLS_MISSIONS: (state, payload) => {
    payload.data.forEach((e) => {
      //console.log(e.missions);
      if (e.users_id == payload.user) {
        e.missions.forEach((k) => {
          if (k.name == "skills") {
            //console.log(k.missions);
            if (k.missions !== null && k.missions !== "") {
              k.missions.forEach(function (mission) {
                state.skillsMissions.unshift(mission);
              });
            }
          }
        });
      }
    });
    //console.log(state.skillsMissions);
  },
  ADD_MINI_MISSION: () => {},

  EDIT_MISSION: (state, mission) => {
    //console.log(mission);

    var index = state.miniMissions.findIndex((item) => item.id === mission.id);

    if (index >= 0) {
      var index2 = state.miniMissions.findIndex(
        (item) => item.id === mission.id
      );

      if (index2 >= 0) {
        state.miniMissions[index2].name = mission.name;
        state.miniMissions[index2].description = mission.description;
        state.miniMissions[index2].sum = mission.sum;
      }
    }
  },

  DELETE_MINI_MISSION: (state, missionId) =>
    (state.miniMissions = state.miniMissions.filter(
      (mission) => mission.id !== missionId
    )),

  FETCH_SIDEBAR_CURRENT_MISSION: (state, currentMission) => {
    state.sidebarCurrentMission = currentMission;
  },

  FETCH_DASHBOARD_ACTIVITY: (state, activities) => {
    state.dashboardActivities = [];
    const missions = activities.filter((item) => item.name == "mini");
    state.dashboardActivities = missions[0].missions;

    state.dashboardMissions.in_progress = missions[0].missions.in_progress;
    state.dashboardMissions.completed = missions[0].missions.completed;
    state.dashboardMissions.failed = missions[0].missions.failed;
    state.dashboardMissions.expired = missions[0].missions.expired;

    // console.log(missions);
  },

  FETCH_DASHBOARD_CHILDRENS: (state, activities) => {
    state.dashboardChildrens = [];
    activities.data.forEach(function (value) {
      state.dashboardChildrens.push(value);
    });
  },

  FETCH_CHILDRENS_LIST: (state, data) => {
    state.childrensList = [];
    localStorage.setItem("activeChildActivity", data.data[0].users_id);
    data.data.forEach(function (child) {
      state.childrensList.push(child);
    });
  },

  FETCH_CHILDRENS_ACTIVITY: (state, payload) => {
    state.childrensActivity = [];
    state.childSuspended = payload.suspended;

    payload.response.data.data.forEach((e) => {
      if (e.id == payload.childId) {
        state.childSuspended = e.suspended;
        if (e) {
          state.childrensActivity = [];
          e.logs.forEach((k) => {
            state.childrensActivity.unshift(k);
            // console.log(k);
          });
        }
      }
    });

    // state.childrensList.forEach((e) => {
    //     if (e.users_id == payload.childId) {
    //         console.log(e);
    //         if (e) {
    //             state.childrensActivity = [];
    //             e.logs.forEach((k) => {
    //
    //                 state.childrensActivity.push(k);
    //                 console.log(k);
    //
    //             })
    //         }
    //     }
    //
    // })

    // console.log(state.childrensActivity);
  },

  REACTIVATE_MISSION: () => {},

  DISMISS_MISSION: (state, missionId) =>
    (state.miniMissions = state.miniMissions.filter(
      (mission) => mission.id !== missionId
    )),

  DELETE_LOG: (state, logId) =>
    (state.childrensActivity = state.childrensActivity.filter(
      (log) => log.id !== logId
    )),
  DELETE_LOGS: (state, payload) => {
    console.log(payload);
    state.childrensActivity = [];
  },

  FETCH_FAMILY_AND_FRIENDS: (state, family) => {
    // console.log(family.data);
    state.familyAndFriends = [];
    state.familyName = family.data.name;
    // console.log(family.friends);
    family.data.families_has_users.forEach(function (value) {
      state.familyAndFriends.push(value);
    });

    //console.log(state.familyAndFriends);
  },
  FETCH_BALANCE: (state, data) => {
    let balance = data.data.guardianAccount;
    let rewards = data.data.guardianGivenTotalToChildren;

    if (balance == null) {
      state.balance = 0;
      state.spendReward = 0;
    } else {
      state.balance = balance;
      state.spendReward = rewards;
    }

    state.guardianTransactions = [];

    data.data.guardianDebitingAccountTransactions.forEach(function (value) {
      state.guardianTransactions.unshift(value);
    });

    // console.log(state.guardianTransactions);

    state.childrensFunds = [];
    state.childrensTransactions = [];

    data.data.childrenFunds.forEach(function (value) {
      state.childrensFunds.unshift(value);

      if (value) {
        value.children_guardian_received_funds_transactions.forEach(function (
          value
        ) {
          state.childrensTransactions.push(value);
        });
      }
    });

    //console.log(state.childrensTransactions);
  },

  FETCH_CHILDRENS_TRANSACTIONS: (state, childId) => {
    state.childrensFunds.forEach((e) => {
      if (e.users_id == childId) {
        e.children_guardian_received_funds_transactions.forEach((k) => {
          // console.log(k);
          if (k.name == "main") {
            if (k.current_balance === null) {
              state.spendingWallet = 0;
            } else {
              state.spendingWallet = k.current_balance;
            }

            if (k.transactions !== null && k.transactions !== "") {
              state.childrensMain = [];
              k.transactions.forEach(function (mission) {
                state.childrensMain.push(mission);
              });
            }
          } else if (k.name == "close") {
            if (k.current_balance === null) {
              state.savingsWallet = 0;
            } else {
              state.savingsWallet = k.current_balance;
            }

            if (k.transactions !== null && k.transactions !== "") {
              state.childrensClosed = [];
              k.transactions.forEach(function (mission) {
                //ca nu am mai schimbat de mai sus a ok
                state.childrensClosed.push(mission);
              });
            }
          }
        });
      }
    });

    // console.log(state.childrensMain);
    // console.log(state.childrensClosed);
  },

  ADD_TRUSTED_GUARDIAN: () => {},

  FETCH_PAST_MISSIONS: (state, data) => {
    state.pastMissions = [];

    data.data.forEach(function (value) {
      state.pastMissions.push(value);
    });
  },

  PAST_MISSION_CONFIRM: () => {},

  PAST_MISSION_DECLINE: () => {},

  FETCH_PROFILE: (state, data) => {
    state.guardianAddresses = [];

    // console.log("debug -------------------------");
    // console.log(data);

    state.guardian.childrens = data.all_children;
    state.guardian.members = data.all_members;
    state.guardianAddresses = data.delivery_address;

    // data.delivery_address.forEach(function(value) {
    //   state.guardianAddresses.push(value);
    // });
    // console.log(state.guardianAddresses);
  },
  DELETE_GUARDIAN_CHILD: (state, childId) => {
    state.dashboardChildrens.forEach(function (children, index) {
      if (children.id == childId) {
        state.dashboardChildrens.splice(index, 1);
        // state.setup.children[index] = null;
      }
    });
  },

  ADD_GUARDIAN_ADDRESS_ACTION: () => {},

  EDIT_GUARDIAN_ADDRESS: () => {},

  DELETE_GUARDIAN_ADDRESS_ACTION: () => {},

  FETCH_STARS: (state, data) => {
    // console.log(data.data);
    state.ratingStars = 0;
    state.ratingStars = data.data;
  },

  EDIT_GUARDIAN: () => {},

  LOGIN_REQUESTS: (state, data) => {
    state.loginRequests = [];
    state.loginRequests = data.data;
    state.hasNotifications =
      Object.keys(data.data.pending_logins).length > 0 ? true : false;
    // data.data.forEach(function(value) {
    //   state.loginRequests.push(value);
    // });

    // console.log(state.loginRequests);
  },

  ACTIVATE_DEVICE: () => {},

  DEACTIVATE_DEVICE: () => {},

  FETCH_WISHLIST_INDEX: (state, wishlist) => {
    wishlist.data.forEach(function (value) {
      if (state.wishlistIndex.findIndex((item) => item.id === value.id) < 0) {
        state.wishlistIndex.push(value);
      }
    });
  },
  EDIT_MISSION_STATUS: (state, missionId) => {
    // Set status to missions index
    var indexInState = state.missionsIndex.findIndex(
      (item) => item.id === missionId
    );
    if (indexInState > -1) {
      state.missionsIndex[indexInState].status = "pending reward";
    }

    // Set status to mini missions
    indexInState = state.miniMissions.findIndex(
      (item) => item.id === missionId
    );
    if (indexInState > -1) {
      state.miniMissions[indexInState].status = "pending reward";
    }

    // // Set status to skill missions
    indexInState = state.skillMissions.findIndex(
      (item) => item.id === missionId
    );
    if (indexInState > -1) {
      state.skillMissions[indexInState].status = "pending reward";
    }
  },
  // FETCH_FAMILY_AND_FRIENDS: (state, data) => {
  //     // console.log(data.details);
  //     data.details.forEach(function (value) {
  //         if (state.familyAndFriends.findIndex(item => item.id === value.id) < 0) {
  //             state.familyAndFriends.push(value);
  //         }
  //     });
  // },
  // FETCH_MISSIONS: (state, missions) => {
  //     missions.details[0].missions.forEach(function (value) {
  //         if(state.miniMissions.findIndex(item => item.id === value.id) < 0) {
  //             state.miniMissions.push(value);
  //         }
  //     });
  // },
  FETCH_GOALS: (state, goals) => {
    goals.data.forEach(function (value) {
      if (state.goals.findIndex((item) => item.id === value.id) < 0) {
        state.goals.push(value);
      }
    });
  },
  ADD_USER: (state, details) => {
    const child = {
      id: details.response.id,
      avatar: details.response.avatarLinkEncode,
      first_name: details.response.first_name,
      last_name: details.response.last_name,
      date_birth: details.response.date_birth,
      username: details.response.username,
      password: details.response.password,
      balance: 0,
    };

    state.setup.children.push(child);
    state.lastAddedChild = child;
    state.childAddModal = false;
    console.log("child", child);
  },
  // ADD_USER: () => { },
  DELETE_CHILD_ACTION: (state, childId) => {
    state.setup.children.forEach(function (children, index) {
      if (children.id == childId) {
        state.setup.children.splice(index, 1);
        // state.setup.children[index] = null;
      }
    });
  },
  EDIT_CHILD_ACTION: (state, child) => {
    state.setup.children.forEach(function (children, index) {
      if (children.id == child.id) {
        state.setup.children[index].first_name = child.first_name;
        state.setup.children[index].last_name = child.last_name;
        state.setup.children[index].username = child.username;
        state.setup.children[index].avatar = child.avatar;
        state.setup.children[index].date_birth = child.date_birth;
      }
    });
  },
  FETCH_SETUP_CHILDRENS: (state, childrens) => {
    // console.log(childrens);

    state.setup.children = [];

    childrens.forEach(function (children) {
      try {
        let newchildren = {
          id: children.user.id,
          first_name: children.user.first_name,
          last_name: children.user.last_name,
          username: children.user.username,
          date_birth: children.user.date_birth,
          avatar: children.user.avatarLinkEncode,
          balance: children.user.CurrentBalance,
          password: children.user.password,
        };
        state.setup.children.push(newchildren);
      } catch (error) {
        console.error(children);
      }
    });

    // childrens.forEach(function(children) {
    //   if (
    //     state.setup.children.findIndex((item) => item.id === children.user.id) <
    //     0
    //   ) {
    //     try {
    //       var newchildren = {
    //         id: children.user.id,
    //         first_name: children.user.first_name,
    //         last_name: children.user.last_name,
    //         username: children.user.username,
    //         date_birth: children.user.date_birth,
    //         avatar: children.user.avatarLinkEncode,
    //       };
    //       state.setup.children.push(newchildren);
    //     } catch (error) {
    //       console.error(children);
    //     }
    //   }
    // });

    if (childrens.length > 0) {
      state.setup.activeChildId = childrens[0].user.id;
    }
  },
  SAVE_SETUP_MISSION: (state, mission) => {
    // console.log(mission);
    var addedMission = {
      id: mission.id,
      name: mission.name,
      description: mission.description,
      sum: mission.sum,
    };
    var indexOfChild = state.setup.missions.findIndex(
      (item) => item.users_id === mission.status_mission.child_id
    );
    if (indexOfChild >= 0) {
      state.setup.missions[indexOfChild].missions[0].missions.unshift(
        addedMission
      );
    }
  },
  FETCH_SETUP_MISSIONS: (state, details) => {
    // console.log("misiuni");
    // console.log(details);
    // console.log("misiuni");
    details.forEach(function (child) {
      if (state.setup.missions.findIndex((item) => item.id === child.id) < 0) {
        state.setup.missions.unshift(child);
      }
    });
    // console.log(state.setup.missions);
  },
  FETCH_SETUP_MISSIONS_CHILD: (state, details) => {
    state.setup.missions = [];
    // console.log(details.data);
    details.data.forEach(function (missions) {
      if (missions.user.id == state.setup.activeChildId) {
        state.setup.missions = missions.missions[0].missions;
      }
    });
    // console.log(state.setup.missions);
  },
  // DELETE_SETUP_MISSION: (state, details) => {
  //   console.log(state.setup.missions);
  //   console.log(details);
  //   if (details.type == "mini") {
  //     var indexOfChild = state.setup.missions.findIndex(
  //       (item) => item.users_id === details.childId
  //     );
  //     if (indexOfChild >= 0) {
  //       // console.log(state.setup.missions[indexOfChild].missions[0].missions);
  //       var indexOf = state.setup.missions[
  //         indexOfChild
  //       ].missions[0].missions.findIndex((item) => item.id === details.id);
  //       if (indexOf >= 0) {
  //         state.setup.missions[indexOfChild].missions[0].missions.splice(
  //           indexOf,
  //           1
  //         );
  //       }
  //       // console.log(indexOf);
  //     }
  //   }
  // },

  DELETE_SETUP_MISSION: (state, missionId) =>
    (state.setup.missions = state.setup.missions.filter(
      (mission) => mission.id !== missionId.id
    )),

  FINISH_WIZARD_PAGE: () => {},
  EDIT_SETUP_MISSION: (state, mission) => {
    // console.log(state.setup.missions);
    var index = state.setup.missions.findIndex(
      (item) => item.users_id === mission.activeChildId
    );

    if (index >= 0) {
      var index2 = state.setup.missions[index].missions[0].missions.findIndex(
        (item) => item.id === mission.id
      );
      // console.log(index2);

      if (index2 >= 0) {
        state.setup.missions[index].missions[0].missions[index2].name =
          mission.name;
        state.setup.missions[index].missions[0].missions[index2].description =
          mission.description;
        state.setup.missions[index].missions[0].missions[index2].sum =
          mission.sum;
      }
    }
  },
  SUSPEND_CHILD: () => {},
  FETCH_NOTIFICATIONS: (state, data) => {
    state.guardianMobile = data.phone;
    state.guardianEmail = data.email;
    state.guardianPhoneNumber = data.phone_number;
  },
  EDIT_NOTIFICATIONS: () => {},
  FETCH_MISSION_WISHLIST: (state, data) => {
    state.missionWishlist = [];
    if (data) {
      data.forEach(function (item) {
        state.missionWishlist.push(item.product);
      });
    }
  },
  FETCH_YOUTUBE_VIDEOS(state, data) {
    state.youtubeVideos = data;
  },
  SET_WIZARD_STEP(state, data) {
    state.wizardStep = data;
  },
  UPDATE_WIZARD_CURRENT_STEP() {},
  FETCH_TRANSACTIONS(state, data) {
    state.transactions = data;
  },
  FETCH_PENDING_REWARD_MISSIONS(state, data) {
    let missions = data.filter((item) => item.name == "mini");
    state.pendingRewardMissions = missions[0].subcategory.pending_reward;
    // console.log(missions[0].subcategory.pending_reward)
  },
  FETCH_GUARDIAN_TRANSACTIONS(state, data) {
    const sortedViagra = data.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    state.guardian_transactions = data.sort(
      (a, b) => b.created_at - a.created_at
    );
  },
};
