//check if localstorage values are set
export const checkLocalStorage = (value) => {
  if (value === "child") {
    if (
      localStorage.getItem("children_token") &&
      localStorage.getItem("children_user_id") &&
      localStorage.getItem("children_family_id") &&
      localStorage.getItem("user_type") === "child"
    ) {
      console.log("child localstorage values are set");
      return true;
    } else {
      console.log("child localstorage values are not set");
      return false;
    }
  }

  if (value === "guardian") {
    if (
      localStorage.getItem("guardian_token") &&
      localStorage.getItem("guardian_user_id") &&
      localStorage.getItem("guardian_family_id") &&
      localStorage.getItem("user_type") === "guardian"
    ) {
      console.log("guardian localstorage values are set");
      return true;
    } else {
      console.log("guardian localstorage values are not set");
      return false;
    }
  }
};
