import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";
import VueAxios from "vue-axios";
import router from "../../../router";

Vue.use(Vuex);
Vue.use(VueAxios, axios);

import config from "../config";

axios.defaults.baseURL = config.API_HOST;

import status from "../utils/statusCode";

import { store } from "./store";

const user = {
  userId: localStorage.getItem("guardian_user_id"),
  token: localStorage.getItem("guardian_token"),
};

var missionChildId = null;

function deleteStorage() {
  window.localStorage.removeItem("guardian_token");
  window.localStorage.removeItem("userId");
  window.localStorage.removeItem("user_type");
  window.localStorage.removeItem("guardian_family_id");
  window.localStorage.removeItem("guardian_setup_active_child_id");
  window.localStorage.removeItem("guardian_wizard_step");
  window.localStorage.removeItem("activeChild");
  window.localStorage.removeItem("activeChildBalance");
  window.localStorage.removeItem("activeChildActivity");
  localStorage.clear();
  router.replace("/");
}

export default {
  TOAST({ commit }, msg) {
    Vue.prototype.$toast(msg);
    commit("TOAST", msg);
  },

   setupUser({ commit, dispatch }, user) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

     axios
      .post("/startPage", {
        token: token,
        userId: userId,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          var account = response.data.data[0][0].user;
          commit("SETUP_USER", {
            user,
            account,
          });
          dispatch("fetchChildrensList");
          dispatch("fetchSetupChildrens");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  getMissionsCategories({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/missionsCategories?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const data = response.data.data;
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("GET_MISSIONS_CATEGORIES", data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchUser({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

     axios
      .post("/user/get", {
        token: token,
        userId: userId,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          var account = response.data.data;
          commit("FETCH_USER", {
            user,
            account,
          });
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchChildrens({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    // console.log("fetch from action");

    axios
      .post("/children", {
        userId: userId,
        token: token,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_CHILDRENS", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchMiniMissions({ commit }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    this.state.miniMissionsNew = [];

    axios
      .get(
        "/missions" +
          "?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          let payload = {
            user: childId,
            data: response.data.data,
          };
          commit("FETCH_MINI_MISSIONS", payload);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async fetchMiniMissionsNew({ commit, state }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    this.state.miniMissions = [];
    missionChildId = childId;
    store.state.wizardLoading = true;

    await axios
      .get(
        "/missions/index" +
          "?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          store.state.wizardLoading = false;

          let payload = {
            user: childId,
            data: response.data.data,
          };

          commit("FETCH_MINI_MISSIONS_NEW", payload);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        state.componentKey += 1;
      });
  },
  async fetchMiniMissionsLength({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    store.state.wizardLoading = true;

    await axios
      .get(
        "/missions/index" +
          "?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          store.state.wizardLoading = false;

          commit("FETCH_MINI_MISSIONS_LENGTH", response.data.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchMegaMissions({ commit }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    this.state.megaMissions = [];

    axios
      .get(
        "/missions" +
          "?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          let payload = {
            user: childId,
            data: response.data.data,
          };

          commit("FETCH_MEGA_MISSIONS", payload);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchSkillsMissions({ commit }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    this.state.skillsMissions = [];

    axios
      .get(
        "/missions" +
          "?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          let payload = {
            user: childId,
            data: response.data.data,
          };

          commit("FETCH_SKILLS_MISSIONS", payload);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  addMiniMission({ commit, dispatch, state }, mission) {
    //console.log(mission);
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    const parameters = {
      token: token,
      userId: userId,
      family_id_check: family,
      name: mission.name,
      description: mission.description,
      sum_money: mission.sum_money,
      sum_points: mission.sum_points,
      reward_type: mission.reward_type,
      missionCategoryId: mission.categoryId,
      guardian_id: userId,
      child_id: mission.child_id,
      product_id: mission.product_id,
      is_reward_visible: mission.is_reward_visible,
    };

    if (mission.time_condition) {
      parameters.time_conditions = mission.time_condition;
    }

    axios
      .post("/missions/add", parameters)
      .then((response) => {
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          commit("ADD_MINI_MISSION", response.data.data);
          dispatch("fetchMiniMissionsNew", missionChildId);
          Vue.prototype.$toast("Mission added successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        state.componentKey += 1;
      });
  },
  deleteMiniMission({ commit, dispatch }, missionId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        `/missions/delete/${missionId}?userId=${userId}&token=${token}&family_id_check=${family}`
      )
      .then((response) => {
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          commit("DELETE_MINI_MISSION", missionId);
          dispatch("fetchMiniMissionsNew", missionChildId);
          Vue.prototype.$toast("Mission deleted successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  editMission({ commit, dispatch, state }, mission) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    const parameters = {
      token: token,
      userId: userId,
      family_id_check: family,
      name: mission.name,
      description: mission.description,
      sum_money: mission.sum_money,
      sum_points: mission.sum_points,
      reward_type: mission.reward_type,
      missionCategoryId: mission.categoryId,
      guardian_id: userId,
      child_id: mission.child_id,
      product_id: mission.product_id,
      is_reward_visible: mission.reward_hidden,
    };

    if (mission.time_condition) {
      parameters.time_conditions = mission.time_condition;
    }

    axios
      .post("/missions/save/" + mission.mission_id, parameters)
      .then((response) => {
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          commit("EDIT_MISSION", mission);
          dispatch("fetchMiniMissionsNew", missionChildId);
          Vue.prototype.$toast("Mission has been edited successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch(() => {
        Vue.prototype.$toast("It looks like we have some problems here!");
      })
      .finally(() => {
        state.componentKey += 1;
      });
  },
  fetchSidebarCurrentMission({ commit }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/missions/rightSiteMission/" +
          childId +
          "?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          let currentMission = response.data.data;
          commit("FETCH_SIDEBAR_CURRENT_MISSION", currentMission);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
   fetchDasboardActivity({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

     axios
      .get(
        "/missions/index?get_just_3=true&" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_DASHBOARD_ACTIVITY", response.data.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchDasboardChildrens({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post(
        "/guardian/children?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_DASHBOARD_CHILDRENS", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchFamilyAndFriends({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post(
        "/familyAndFriends?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_FAMILY_AND_FRIENDS", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchChildrensList({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post(
        "/logs/children?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_CHILDRENS_LIST", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchChildrensActivity({ commit }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post(
        "/logs/children?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_CHILDRENS_ACTIVITY", { childId, response });
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  reactivateMission({ commit, dispatch }, payload) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        `/missions/change-active-or-dismiss/${payload.mission_id}/reactivate?userId=${userId}&token=${token}&family_id_check=${family}`
      )
      .then((response) => {
        const { statusCode } = response.data;
        //console.log(payload);
        if (status.success(statusCode)) {
          commit("REACTIVATE_MISSION");
          dispatch("fetchMiniMissions", payload.child_id);
          Vue.prototype.$toast("Mission reactivated successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  dismissMission({ commit }, missionId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        `/missions/change-active-or-dismiss/${missionId}/dismiss?userId=${userId}&token=${token}&family_id_check=${family}`
      )
      .then((response) => {
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          commit("DISMISS_MISSION", missionId);
          Vue.prototype.$toast("Mission dismissed successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  deleteLog({ commit }, logId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post(`/logs/delete/${logId}`, {
        token: token,
        userId: userId,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          commit("DELETE_LOG", logId);
          Vue.prototype.$toast("Log deleted successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  deleteLogs({ commit }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    //console.log(childId);
    axios
      .post(`/logs/deleteAll/${childId}`, {
        token: token,
        userId: userId,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("DELETE_LOGS", response.data);
          Vue.prototype.$toast("Logs deleted successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
   fetchBalance({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

     axios
      .post(
        "/accounts/AllFunds?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_BALANCE", response.data);
          // console.log("fetching balance");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchPastMissions({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post(
        "/missions/past?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_PAST_MISSIONS", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  pastMissionConfirm({ commit, dispatch }, missionId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/missions/confirm-infirm/" +
          missionId +
          "?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family +
          "&status=completed"
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("PAST_MISSION_CONFIRM", response.data);
          dispatch("fetchMiniMissionsNew", missionChildId);
          Vue.prototype.$toast("Mission confirmed successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  pastMissionDecline({ commit, dispatch }, missionId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/missions/confirm-infirm/" +
          missionId +
          "?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family +
          "&status=failed"
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("PAST_MISSION_DECLINE", response.data);
          dispatch("fetchMiniMissionsNew", missionChildId);
          Vue.prototype.$toast("Mission declined successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  doGiveRewardAction({ dispatch }, mission) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    var requestUrl = "";

    if (mission.address_id != null) {
      requestUrl =
        "/missions/give-reward?mission_id=" +
        mission.mission_id +
        "&" +
        "userId=" +
        userId +
        "&token=" +
        token +
        "&family_id_check=" +
        family +
        "&give_reward_all=1&delivery_address_id=" +
        mission.address_id;
    } else {
      requestUrl =
        "/missions/give-reward?mission_id=" +
        mission.mission_id +
        "&" +
        "userId=" +
        userId +
        "&token=" +
        token +
        "&family_id_check=" +
        family +
        "&give_reward_all=1";
    }

    axios
      .get(requestUrl)
      .then((response) => {
        const { statusCode } = response.data;
        console.log(response);

        if (status.success(statusCode)) {
          // commit("PAST_MISSION_CONFIRM", response.data);
          dispatch("fetchMiniMissionsNew", missionChildId);
          dispatch("fetchPendingRewardMissions");
          Vue.prototype.$toast("Reward sent successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  dontGiveRewardAction({ dispatch }, missionId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/missions/give-reward?mission_id=" +
          missionId +
          "&" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family +
          "&give_reward_all=0"
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          // commit("PAST_MISSION_CONFIRM", response.data);
          dispatch("fetchMiniMissionsNew", missionChildId);
          Vue.prototype.$toast("Reward sent successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
   fetchProfile({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

     axios
      .post(
        "/guardian/profile?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        // console.log(response)
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_PROFILE", response.data.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteGuardianChild({ commit, dispatch }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post("/users/delete/" + childId, {
        userId: userId,
        token: token,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("DELETE_GUARDIAN_CHILD", childId);
          dispatch("fetchChildrens");
          Vue.prototype.$toast("Child deleted successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  addGuardianAddressAction({ commit, dispatch }, { formData }) {
    const userToken = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    const { fullAddress, type, is_default } = formData;

    const parameters = {
      token: userToken,
      userId: userId,
      family_id_check: family,
      address: fullAddress,
      type: type,
      is_default: is_default,
    };

    axios
      .post("/deliveryAddress/add", parameters)
      .then((response) => {
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          commit("ADD_GUARDIAN_ADDRESS_ACTION", response.data);
          dispatch("fetchProfile");
          Vue.prototype.$toast(response.data.message);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteGuardianAddressAction({ commit, dispatch }, addressId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post("/deliveryAddress/delete/" + addressId, {
        userId: userId,
        token: token,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          //console.log(response.data);
          commit("DELETE_GUARDIAN_ADDRESS_ACTION", addressId);
          dispatch("fetchProfile");
          Vue.prototype.$toast("Address deleted successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchChildrensTransactions({ commit }, childId) {
    console.log(childId);
    commit("FETCH_CHILDRENS_TRANSACTIONS", childId);
  },
  addTrustedGuardian({ commit }, formData) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    const { firstName, lastName, username, email, relationship } = formData;

    //console.log(formData);

    const parameters = {
      token,
      userId,
      family_id_check: family,
      first_name: firstName,
      last_name: lastName,
      username: username,
      email: email,
      relativeRelation: relationship,
    };

    //console.log(parameters);

    axios
      .post("/invitationRequestTrustedGuardian/add", parameters)
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("ADD_TRUSTED_GUARDIAN", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchStars({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/averageStars/" +
          userId +
          "/user?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_STARS", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  editGuardianAction({ commit, dispatch }, guardian) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post("/guardian/profile/save", {
        userId: userId,
        token: token,
        family_id_check: family,
        username: guardian.username,
        password: guardian.password,
        first_name: guardian.first_name,
        last_name: guardian.last_name,
        avatar: guardian.avatar,
        phone_number: guardian.phone_number,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          response.data.data.avatar = guardian.avatar;
          commit("EDIT_GUARDIAN", response.data.data);
          dispatch("fetchUser");
          Vue.prototype.$toast("Your profile has been edited successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  editGuardianAddress({ commit, dispatch }, address) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    //console.log("----editchild action");
    //console.log(address);
    //console.log("----editchild action");
    axios
      .post("/deliveryAddress/save/" + address.id, {
        userId: userId,
        token: token,
        family_id_check: family,
        street: address.street,
        town: address.town,
        city: address.city,
        type: address.type,
        number: address.number,
        post_code: address.postcode,
        country: address.country,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          //console.log(response.data);
          //console.log("updatat");
          commit("EDIT_GUARDIAN_ADDRESS", response.data);
          dispatch("fetchProfile");
          Vue.prototype.$toast("Your address has been edited successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchLoginRequests({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    axios
      .get(
        "/userAgents/test?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family + "&timezone=" + timezone
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("LOGIN_REQUESTS", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  activateDevice({ commit, dispatch }, form) {
    axios
      .get(`/active_user_device/${form.usrId}/${form.usrToken}/activate`)
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("ACTIVATE_DEVICE", response.data);
          dispatch("fetchLoginRequests");
          Vue.prototype.$toast("Confirmed successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deactivateDevice({ commit, dispatch }, form) {
    axios
      .get(
        "/active_user_device/" +
          form.usrId +
          "/" +
          form.usrToken +
          "/deactivate"
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("DEACTIVATE_DEVICE", response.data);
          dispatch("fetchLoginRequests");
          Vue.prototype.$toast("Deactivated successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchWishlistIndex({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post("/wishlist/children", {
        token: token,
        userId: userId,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_WISHLIST_INDEX", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  editMssionStatus({ commit }, missionId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post("/missions/finish/" + missionId, {
        token: token,
        userId: userId,
        family_id_check: family,
        child_id: userId,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("EDIT_MISSION_STATUS", missionId);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchGoals({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/goalsDashboard/children?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_GOALS", response.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addChildAction({ commit }, child) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    // var vm = this;

    axios
      .post("/users/add", {
        userId: userId,
        token: token,
        family_id: family,
        username: child.username,
        password: child.password,
        first_name: child.first_name,
        last_name: child.last_name,
        sex: child.gender,
        type: "child",
        date_birth: child.date,
        avatar: child.avatar,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          if (response.data.data) {
            const details = {
              child: child,
              response: response.data.data,
            };
            commit("ADD_USER", details);
            // location.reload();
            // store.dispatch("fetchSetupChildrens");
            // dispatch("fetchDasboardChildrens");
            // store.dispatch("fetchChildrens");

            setTimeout(() => {
              Vue.prototype.$toast("Your child was added successfully!");
            }, 2000);
          } else {
            Vue.prototype.$toast(response.data.message);
          }
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteChildAction({ commit, dispatch }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    axios
      .post("/users/delete/" + childId, {
        userId: userId,
        token: token,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          //(response.data);

          commit("DELETE_CHILD_ACTION", childId);
          // store.dispatch("fetchSetupChildrens");
          // dispatch("fetchDasboardChildrens");
          // store.dispatch("fetchChildrens");

          Vue.prototype.$toast("Your child was deleted successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  editChildAction({ commit, dispatch }, child) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    // console.log("----editchild action");
    // console.log(child);
    // console.log("----editchild action");
    axios
      .post(`/users/save/${child.id}`, {
        userId: userId,
        token: token,
        family_id_check: family,
        user_id: child.id,
        username: child.username,
        password: child.password,
        first_name: child.first_name,
        last_name: child.last_name,
        type: "child",
        date_birth: child.date_birth,
        avatar: child.avatar,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          response.data.data.avatar = child.avatar;
          //console.log(response.data);
          //console.log("updatat");
          commit("EDIT_CHILD_ACTION", response.data.data);
          dispatch("fetchChildrens");

          Vue.prototype.$toast("Your child was edited successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchSetupChildrens({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    store.state.wizardLoading = true;

    axios
      .post("/children", {
        userId: userId,
        token: token,
        family_id_check: family,
      })
      .then((response) => {
        // console.log("children fetched")
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          store.state.wizardLoading = false;
          commit("FETCH_SETUP_CHILDRENS", response.data.data);
          if (response.data.data.length > 0) {
            localStorage.setItem(
              "guardian_setup_active_child_id",
              response.data.data[0].users_id
            );
          }
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  saveSetupMission({ commit, dispatch }, mission) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    const parameters = {
      token: token,
      userId: userId,
      family_id_check: family,
      name: mission.name,
      description: mission.description,
      sum: mission.sum,
      categoryId: mission.categoryId,
      guardian_id: userId,
      child_id: mission.child_id,
    };

    if (mission.time_condition) {
      parameters.time_condition = mission.time_condition;
    }
    axios
      .post("/missions/add", parameters)
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("SAVE_SETUP_MISSION", response.data.data);
          dispatch("fetchSetupMissionsChild");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  editSetupMission({ commit, dispatch }, mission) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    const parameters = {
      token: token,
      userId: userId,
      family_id_check: family,
      name: mission.name,
      description: mission.description,
      sum: mission.sum,
    };

    if (mission.time_condition) {
      parameters.time_condition = mission.time_condition;
    }
    axios
      .post("/missions/save/" + mission.id, parameters)
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("EDIT_SETUP_MISSION", mission);
          dispatch("fetchSetupMissionsChild");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchSetupMissions({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    axios
      .get(
        "/missions" +
          "?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_SETUP_MISSIONS", response.data.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchSetupMissionsChild({ commit, state }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    axios
      .get(
        "/missions" +
          "?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("fetchSetupChildrens", {
            data: response.data.data,
            id: state.setup.activeChildId,
          });
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  deleteSetupMission({ commit, dispatch }, details) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        `/missions/delete-dateTime/${details.id}?userId=${userId}&token=${token}&family_id_check=${family}`
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("DELETE_SETUP_MISSION", details);
          dispatch("fetchSetupMissionsChild");
          Vue.prototype.$toast("Mission deleted successfully!");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  finishWizardPage({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post("/fuPage", {
        token: token,
        userId: userId,
        family_id_check: family,
        start_page: 0,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FINISH_WIZARD_PAGE");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  suspendChildAction({ commit }, user) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    axios
      .post(`users/save/${user.child_id}`, {
        token: token,
        userId: userId,
        family_id_check: family,
        suspended: user.is_suspended,
      })
      .then((response) => {
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          commit("SUSPEND_CHILD");
          Vue.prototype.$toast(response.data.message);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  pollingAction() {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const familyId = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/missions" +
          "?userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          familyId
      )
      .then((response) => {
        const missionsList = response.data.data[0].missions[0].missions;
        const missionsCount = missionsList.length;

        if (missionsCount > window.localStorage.getItem("count")) {
          var audio = new Audio(
            "http://pinsaromana.ro/admin/view/javascript/ping.mp3"
          );
          audio.play();
          setTimeout(() => {
            console.log("New Mission!");
          }, 1000);
        }

        // console.log("No new missions!");

        localStorage.setItem("count", missionsCount);
      })
      .catch((error) => {
        console.log(error);
      });
  },
   fetchNotificationsAction({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    // var vm = this;

     axios
      .post(`notifications`, {
        token: token,
        userId: userId,
        family_id_check: family,
      })
      .then((response) => {
        const { statusCode } = response.data;

        if (status.success(statusCode)) {
          commit("FETCH_NOTIFICATIONS", response.data.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  editNotificationsAction({ commit }, user) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .post(`notifications/edit`, {
        token: token,
        userId: userId,
        family_id_check: family,
        email: user.email,
        phone: user.phone,
        phone_number: user.phone_number,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("EDIT_NOTIFICATIONS");
          Vue.prototype.$toast(response.data.message);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchMissionWishlistAction({ commit }, childId) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "wishlist/list/" +
          childId +
          "?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_MISSION_WISHLIST", response.data.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchYouTubeVideosAction({ commit }) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");
    axios
      .get(`/youtube/index`, {
        params: {
          token: token,
          userId: userId,
          family_id_check: family,
        },
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(response.data.statusCode)) {
          commit("FETCH_YOUTUBE_VIDEOS", response.data.data);
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  likeYoutubeAction({ commit, dispatch }, id) {
    axios
      .post(`/youtube/like?video_id=${id}&type=like`, {
        token: localStorage.getItem("guardian_token"),
        userId: localStorage.getItem("guardian_user_id"),
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          this.addDialog = false;
          dispatch("fetchYouTubeVideosAction");
          Vue.prototype.$toast("Video liked successfully!");
          commit("TOAST", "");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  dislikeYoutubeAction({ commit, dispatch }, id) {
    axios
      .post(`/youtube/like?video_id=${id}&type=dislike`, {
        token: localStorage.getItem("guardian_token"),
        userId: localStorage.getItem("guardian_user_id"),
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          this.addDialog = false;
          dispatch("fetchYouTubeVideosAction");
          Vue.prototype.$toast("Video disliked successfully!");
          commit("TOAST", "");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setWizardStepAction({ commit }, step) {
    commit("SET_WIZARD_STEP", step);
  },
  updateWizardCurrentStep({ commit }, step) {
    axios
      .post(`/setup/wizardStep`, {
        token: localStorage.getItem("guardian_token"),
        userId: localStorage.getItem("guardian_user_id"),
        family_id_check: localStorage.getItem("guardian_family_id"),
        number_step: step,
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("UPDATE_WIZARD_CURRENT_STEP");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchTransactionsAction({ commit, state }, id) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/transactions/history/" +
          id +
          "?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        if (status.success(response.status)) {
          commit("FETCH_TRANSACTIONS", response.data);
        } else if (status.invalidToken(response.status)) {
          deleteStorage();
        } else if (status.error(response.status)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
   fetchPendingRewardMissions({ commit }) {
     axios
      .post(`/missions/pending_rewards`, {
        token: localStorage.getItem("guardian_token"),
        userId: localStorage.getItem("guardian_user_id"),
      })
      .then((response) => {
        const { statusCode } = response.data;
        if (status.success(statusCode)) {
          commit("FETCH_PENDING_REWARD_MISSIONS", response.data.data);
          // console.log("fetching pending missions");
        } else if (status.invalidToken(statusCode)) {
          deleteStorage();
        } else if (status.error(statusCode)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  fetchGuardianTransactionsAction({ commit }, id) {
    const token = localStorage.getItem("guardian_token");
    const userId = localStorage.getItem("guardian_user_id");
    const family = localStorage.getItem("guardian_family_id");

    axios
      .get(
        "/transaction?" +
          "userId=" +
          userId +
          "&token=" +
          token +
          "&family_id_check=" +
          family
      )
      .then((response) => {
        if (status.success(response.status)) {
          commit("FETCH_GUARDIAN_TRANSACTIONS", response.data.data);
        } else if (status.invalidToken(response.status)) {
          deleteStorage();
        } else if (status.error(response.status)) {
          Vue.prototype.$toast(
            "Something went wrong! Please contact our support team."
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
